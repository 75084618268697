<template class="white-bg">
  <div class="ibx-404">
    <img src="assets/img/404.png" alt="404-Error" />
    <h1>404 Error Page</h1>
    <p>Oh no! Looks like we can't find the page you are looking for.</p>
  </div>
</template>

<script>
export default {
  name: 'fourOhFour',
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx {
  background-color: #fff !important;
}

.ibx-404 {
  display: block;
  margin: 0 auto;
  margin-top: 158px;
  width: 250px;
  text-align: center;
  font-family: 'CerebriSans', Roboto, sans-serif;

  img {
    height: 200px;
    margin-bottom: 16px;
  }
}
</style>
